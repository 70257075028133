/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ComponentProps, ElementType } from 'react';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { DateValue } from '@mantine/dates';
import cn from 'classnames';

import { RadioOption } from '@common/interfaces';
import { FormContext } from './form';
import {
  Checkbox,
  DatePickerCalendar,
  InputDatePicker,
  InputDropdown,
  InputMultiSelect,
  InputNumber,
  InputText,
  InputTime,
  InputTimePicker,
  RadioGroup,
  SegmentControl,
  SwitchInput,
  Textarea,
} from '../components';
import { useSchema } from '../utils';

export type FieldType =
  | 'calendar'
  | 'checkbox'
  | 'date-picker'
  | 'dropdown'
  | 'multi-select'
  | 'number'
  | 'radio'
  | 'segmented-control'
  | 'switch'
  | 'text'
  | 'textarea'
  | 'time'
  | 'time-picker';

export type FieldProps<T extends ElementType> = {
  className?: string;
  input: FieldType;
  name: string;
  wide?: boolean;
} & ComponentProps<T>;

export function Field<T extends ElementType>({
  className,
  input = 'text',
  name,
  onChange,
  wide,
  ...props
}: FieldProps<T>) {
  const { mode } = useContext(FormContext);
  const { formState, clearErrors, register, resetField, setError, setValue, watch } =
    useFormContext();
  const { required, validate } = useSchema(name);
  const hasErrors = formState?.isSubmitted || mode === 'onChange';

  const handleValidation = useCallback(
    (value: any, hasError: boolean) => {
      try {
        validate(value);
        clearErrors(name);
      } catch (e: any) {
        if (hasError) setError(name, { ...e });
      }
    },
    [clearErrors, name, setError, validate],
  );

  const handleChange = useCallback(
    (value: any) => {
      handleValidation(value, hasErrors);
      setValue(name, value);
      onChange?.(value);
    },
    [handleValidation, hasErrors, name, onChange, setValue],
  );

  const handleFieldChange = useCallback(
    (e: any) => {
      switch (input) {
        case 'text':
        case 'textarea':
        case 'time':
          return handleChange(e.target.value as string);
        case 'calendar':
          return handleChange(e as DateValue | null);
        case 'checkbox':
        case 'switch':
          return handleChange(e.currentTarget.checked as boolean);
        case 'date-picker':
          return handleChange(e as Date | null);
        case 'dropdown':
          return handleChange(e as string | null);
        case 'multi-select':
          return handleChange(e as string[]);
        case 'number':
          return handleChange(e as number | null);
        case 'radio':
        case 'segmented-control':
        case 'time-picker':
          return handleChange(e as string);
        default:
          return null;
      }
    },
    [handleChange, input],
  );

  useEffect(() => {
    register(name);
    handleValidation(watch(name), formState?.isSubmitted);
    return () => {
      clearErrors(props.name);
      resetField(props.name);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FieldComponent: ElementType | null = useMemo(() => {
    const componentMap: Record<FieldType, ElementType | null> = {
      calendar: DatePickerCalendar,
      checkbox: Checkbox,
      'date-picker': InputDatePicker,
      dropdown: InputDropdown,
      'multi-select': InputMultiSelect,
      number: InputNumber,
      radio: RadioGroup,
      switch: SwitchInput,
      text: InputText,
      textarea: Textarea,
      'segmented-control': SegmentControl,
      time: InputTime,
      'time-picker': InputTimePicker,
    };

    return componentMap[input as FieldType] || null;
  }, [input]);

  if (!FieldComponent) return null;

  const baseProps = {
    name,
    required,
    value: watch(name),
    onChange: handleFieldChange,
  };

  const specificProps = {
    ...props,
    ...(input === 'dropdown' || input === 'multi-select' ? { options: props.options } : {}),
    ...(input === 'radio' ? { options: (props.options ?? []) as RadioOption[] } : {}),
  };

  return (
    <div className={cn('field', { 'field-wide': wide }, className)}>
      <FieldComponent {...baseProps} {...specificProps} />
    </div>
  );
}

export default Field;
