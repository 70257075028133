/* istanbul ignore file */
import { type FC, useEffect, useMemo, useState } from 'react';
import { Checkbox } from '@mantine/core';

import { Button, Dialog } from '@components';
import { EventExtProps, ShuttleStop } from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import { formatDateString } from '@common/utils';
import { Process, Shuttle, Tyre } from '@assets/svg/icons';
import './DrivePreview.styles.scss';

interface DrivePreviewProps {
  data: EventExtProps | null;
  onCancel: () => void;
  onSubmit: (payload: { dropoffTime: string; pickupTime: string }) => void;
}

const DrivePreview: FC<DrivePreviewProps> = ({ data, onCancel, onSubmit }) => {
  const [selectedStops, setSelectedStops] = useState<string[]>([]);
  const shuttleStops = useMemo(() => data?.shuttleConfig?.stops || [], [data]);

  useEffect(() => {
    if (shuttleStops.length > 0 && data) {
      const pickupStop =
        shuttleStops.find(
          (stop) => stop.location === data.pickupLocation && stop.town === data.pickupTown,
        ) || shuttleStops[0];

      const dropoffStop =
        shuttleStops.find(
          (stop) => stop.location === data.dropoffLocation && stop.town === data.dropoffTown,
        ) || shuttleStops[shuttleStops.length - 1];

      const selected = [];
      if (pickupStop) selected.push(`${pickupStop.location}-${pickupStop.town}`);
      if (dropoffStop) selected.push(`${dropoffStop.location}-${dropoffStop.town}`);

      setSelectedStops(selected);
    }
  }, [shuttleStops, data]);

  const handleCheckboxChange = (selected: string[]) => {
    setSelectedStops(selected.length <= 2 ? selected : []);
  };

  const handleSubmit = () => {
    if (shuttleStops && selectedStops.length === 2) {
      const [start, end] = selectedStops;
      const firstStop = shuttleStops.find((stop) => `${stop.location}-${stop.town}` === start);
      const secondStop = shuttleStops.find((stop) => `${stop.location}-${stop.town}` === end);
      if (firstStop && secondStop)
        onSubmit({ pickupTime: firstStop.pickupTime, dropoffTime: secondStop.dropoffTime });
    } else if (data) {
      onSubmit({
        pickupTime: formatDateString(data.pickupTime, DF.ApiTimeFull, DF.ApiTime),
        dropoffTime: formatDateString(data.dropoffTime, DF.ApiTimeFull, DF.ApiTime),
      });
    }
  };

  return (
    <Dialog
      className="dialog-scheduled-drive"
      opened={!!data}
      position={{ bottom: 35, right: 25 }}
      onClose={onCancel}
    >
      <header>
        <h2>
          Do you want to add drive
          {data?.isShuttleEvent ? ' to shuttle' : ' ride shared'}?
        </h2>
      </header>

      <section>
        {data && (
          <>
            <dl className="scheduled-drive-item">
              <dt>
                <Tyre />
              </dt>
              <dd>
                <span>Driver</span>
                <p>
                  {data.driverFirstName} {data.driverLastName}, {data.driverAgency}
                </p>
                <p className="sub">{data.driverEmail}</p>
              </dd>
            </dl>

            <dl className="scheduled-drive-item">
              <dt>
                <Shuttle />
              </dt>
              <dd>
                <span>Vehicle</span>
                <p>{data.vehicleLabel}</p>
                <p className="sub">Pax {data.passengerPax}</p>
              </dd>
            </dl>
          </>
        )}

        {shuttleStops.length ? (
          <Checkbox.Group value={selectedStops} onChange={handleCheckboxChange}>
            {shuttleStops.map((stop: ShuttleStop) => {
              const key = `${stop.location}-${stop.town}`;

              return (
                <Checkbox
                  classNames={{
                    root: 'choice',
                    body: 'choice-item',
                    labelWrapper: 'choice-item-label',
                  }}
                  key={key}
                  value={key}
                  label={
                    <dl className="scheduled-drive-item">
                      <dt>
                        <Process />
                      </dt>
                      <dd>
                        <p className="sub">
                          <b>{stop.dropoffTime}</b>
                          {` ${stop.location} ${stop.town} `}
                          {stop.pickupTime !== stop.dropoffTime && <b>{stop.pickupTime}</b>}
                        </p>
                      </dd>
                    </dl>
                  }
                />
              );
            })}
          </Checkbox.Group>
        ) : (
          <>
            <dl className="scheduled-drive-item">
              <dt>
                <Process />
              </dt>
              <dd>
                <p className="sub">
                  <b>{`${data?.pickupDate} - ${data?.pickupTime}`}</b>
                </p>
              </dd>
            </dl>
            <dl className="scheduled-drive-item">
              <dt>
                <Process />
              </dt>
              <dd>
                <p className="sub">
                  <b>{`${data?.dropoffDate} - ${data?.dropoffTime}`}</b>
                </p>
              </dd>
            </dl>
          </>
        )}
      </section>

      <footer>
        <Button className="btn" text="Cancel" variant="default" onClick={onCancel} />
        <Button
          className="btn"
          text={`Add ${data?.isShuttleEvent ? ' to shuttle' : ' ride shared'}`}
          variant="filled"
          onClick={handleSubmit}
        />
      </footer>
    </Dialog>
  );
};

export default DrivePreview;
