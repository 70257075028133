/* istanbul ignore file */
import type { TFunction as TFunc } from 'react-i18next';
import { Depart, Flag, Fleet, Route, Vehicle } from '@assets/svg/icons';
import { BookingTripType } from '@common/interfaces';
import { BookingFormType, BookingType, TransferTypeOption } from '../types';

export const getFormTypes = (t: TFunc) => [
  { label: t('bookingForm.tabSelf'), value: BookingFormType.Self },
  { label: t('bookingForm.tabColleague'), value: BookingFormType.Colleague },
];

export const getTripTypes = (isShuttle: boolean, t: TFunc) => [
  { value: BookingTripType.OneWay, label: t('common.tripOneWay') },
  { value: BookingTripType.RoundTrip, label: t('common.tripRound') },
  ...(!isShuttle ? [{ value: BookingTripType.MultiLeg, label: t('common.tripMultiLeg') }] : []),
];

export const getTransferTypes = (
  isPrivateHire: boolean,
  isShuttle: boolean,
  hasRoute: boolean,
  t: TFunc,
): TransferTypeOption[] => {
  const createType = (Icon: React.ComponentType, labelKey: string, value: BookingType) => ({
    label: (
      <div className="transfer-type-label">
        <Icon />
        <span>{t(labelKey).toString()}</span>
      </div>
    ),
    value,
  });

  const transferTypes = [];

  if (isShuttle) {
    transferTypes.push(createType(Fleet, 'common.transferTypeShuttle', BookingType.Shuttle));
  } else {
    transferTypes.push(
      createType(Depart, 'common.transferTypeAirport', BookingType.Airport),
      createType(Flag, 'common.inTown', BookingType.InTown),
      createType(Route, 'common.transferTypeOutTown', BookingType.OutTown),
    );

    if (hasRoute) {
      transferTypes.push(createType(Fleet, 'common.transferTypeShuttle', BookingType.Shuttle));
    }

    if (isPrivateHire) {
      transferTypes.push(createType(Vehicle, 'common.privateHire', BookingType.VehiclePrivateHire));
    }
  }

  return transferTypes;
};
