import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { useDebounce } from 'usehooks-ts';

import { Calendar, Check, User, Vehicle } from '@assets/svg/icons';
import { LocationMarker } from '@assets/svg/icons/logbook';
import { AgencyListApi, ApiList, DropdownOption } from '@common/interfaces';
import { useUserInfo } from '@common/hooks';
import { emailValidator, getErrors, Serializer } from '@common/utils';
import { Checkbox, Field } from '@components';
import { useRepository } from '@context';
import { PrivateHireFormType } from '../../../../schema/PrivateHire.schema';
import { BookingFormType } from '../../../../types';

interface FieldsetProps {
  options?: Record<string, DropdownOption[]> | null;
  setValue: UseFormSetValue<PrivateHireFormType>;
  watch: UseFormWatch<PrivateHireFormType>;
}

const Fieldset = ({ options, setValue, watch }: FieldsetProps) => {
  const { t } = useTranslation();
  const { agenciesRepository } = useRepository();
  const userInfo = useUserInfo();

  const [agencyOptions, setAgencyOptions] = useState<DropdownOption[]>([]);
  const [managerEmails, setManagerEmails] = useState<DropdownOption[]>([]);

  const debEmail$ = useDebounce(watch('profileEmail')?.trim(), 800);
  const bookingFormType$ = watch('bookingFormType');
  const isBFColleague = bookingFormType$ === BookingFormType.Colleague;

  const { isLoading: isAgencyLoading, mutateAsync: getAgencies } = useMutation<
    ApiList<AgencyListApi>,
    AxiosError,
    string | undefined
  >(['get-agencies'], (domain) =>
    agenciesRepository.getAgencies({ ...(domain ? { domain } : {}), limit: 250 }),
  );

  useEffect(() => {
    if (!userInfo) return;

    const { email, firstName, lastName } = userInfo;

    if (isBFColleague) {
      setValue('agency', '');
      setValue('firstName', '');
      setValue('lastName', '');
      setValue('profileEmail', '');
      setAgencyOptions([]);
    } else {
      setValue('firstName', firstName);
      setValue('lastName', lastName);
      setValue('profileEmail', email);
    }
  }, [isBFColleague, setValue, userInfo]);

  useEffect(() => {
    const getAgenciesByDomain = async (domain?: string) => {
      try {
        const { results } = await getAgencies(domain);

        if (results?.length === 1 && results[0].domain === 'fallback-agency.org') {
          setValue('agency', '');
          getAgenciesByDomain();
        } else {
          const agencies = results.map(Serializer.formatAgencyOptions);

          setAgencyOptions(agencies);
          setValue('agency', agencies?.length === 1 ? agencies[0].value : '');
        }
      } catch (error: any) {
        if (error.response) {
          setAgencyOptions([]);
          setValue('agency', '');
          toast.error(getErrors(error.response.data));
        }
      }
    };

    if (debEmail$ && typeof emailValidator(debEmail$) !== 'string') {
      const domain = debEmail$.split('@')[1].toLowerCase() || '';

      if (bookingFormType$ === BookingFormType.Self || (isBFColleague && domain)) {
        getAgenciesByDomain(domain);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debEmail$]);

  return (
    <>
      <section className="user-info">
        <h2>
          <span className="icon">
            <User />
          </span>
          {t('mobilityRequest.sectionProfile')}
        </h2>

        <div>
          <p>{`${userInfo?.firstName} ${userInfo?.lastName}`}</p>
          <p>{userInfo?.email}</p>
        </div>
      </section>

      <section>
        <h3>{t('mobilityRequest.sectionProfileText')}</h3>

        <fieldset>
          <Field
            name="firstName"
            disabled={!isBFColleague}
            label={t('bookingForm.requesterFirstName')}
          />
          <Field
            name="lastName"
            disabled={!isBFColleague}
            label={t('bookingForm.requesterLastName')}
          />
          <Field
            name="profileEmail"
            disabled={!isBFColleague}
            label={t('bookingForm.requesterEmail')}
          />
          <Field
            name="agency"
            input="dropdown"
            clearable
            disabled={isAgencyLoading}
            label={t('common.agency')}
            options={agencyOptions}
          />

          <Field name="position" label={t('mobilityRequest.position')} />
          <Field
            name="contractType"
            input="dropdown"
            clearable
            label={t('mobilityRequest.contractType')}
            options={options?.contractType}
          />
          <Field
            name="entryOnDuty"
            input="date-picker"
            clearable
            hideOutsideDates
            label="EOD (Entry On Duty)"
            icon={<Calendar />}
            valueFormat="DD/MM/YYYY"
          />
          <Field
            name="notToExceed"
            input="date-picker"
            clearable
            hideOutsideDates
            label="NTE (Not to Exceed)"
            minDate={new Date()}
            icon={<Calendar />}
            valueFormat="DD/MM/YYYY"
          />
        </fieldset>
      </section>

      <section>
        <h2>
          <span className="icon">
            <LocationMarker />
          </span>
          {t('mobilityRequest.sectionLocation')}
        </h2>

        <fieldset>
          <Field
            name="country"
            input="dropdown"
            label={t('mobilityRequest.labelCountry')}
            options={options?.counties}
          />
          <Field name="dutyStation" label={t('mobilityRequest.labelDutyStation')} />
        </fieldset>

        <h3>{t('mobilityRequest.sectionLocationText')}</h3>
        <fieldset>
          <Field
            name="dateFrom"
            input="date-picker"
            clearable
            hideOutsideDates
            label="From"
            minDate={new Date()}
            icon={<Calendar />}
            valueFormat="DD/MM/YYYY"
          />
          <Field
            name="dateTo"
            input="date-picker"
            clearable
            hideOutsideDates
            label="To"
            minDate={new Date()}
            icon={<Calendar />}
            valueFormat="DD/MM/YYYY"
          />
        </fieldset>
      </section>

      <section>
        <h2>
          <span className="icon">
            <Vehicle />
          </span>
          {t('mobilityRequest.sectionServices')}
        </h2>

        <fieldset>
          <Field
            name="preferredTypeOfVehicle"
            input="dropdown"
            clearable
            label={t('mobilityRequest.labelPreferredVehicle')}
            options={options?.preferredTypeOfVehicle}
          />

          <Field
            name="isDriverNeeded"
            input="switch"
            label={t('mobilityRequest.labelDriverNeeded')}
            size="lg"
            onLabel="YES"
            offLabel="NO"
          />
        </fieldset>
      </section>

      <section>
        <Checkbox name="acceptConditions" label={t('mobilityRequest.sectionServicesText')} />
      </section>

      <section>
        <h2>
          <span className="icon">
            <Check />
          </span>
          {t('mobilityRequest.sectionConfirmation')}
        </h2>

        <fieldset>
          <Field
            name="purpose"
            input="dropdown"
            clearable
            label={t('mobilityRequest.labelPurpose')}
            options={options?.purposeOfTravelRequest}
          />
        </fieldset>
      </section>

      <section>
        <Field name="reason" input="textarea" label={t('mobilityRequest.labelReason')} />
      </section>

      <section>
        <Field
          name="contactEmail"
          input="multi-select"
          clearable
          creatable
          label={t('mobilityRequest.labelManagerEmail')}
          options={managerEmails}
          getCreateLabel={(value: string) => `+ Add ${value}`}
          onCreate={(value: string) => {
            const item = { label: value, value };
            setManagerEmails((prev) => [...prev, item]);
            return item;
          }}
        />
      </section>

      <section>
        <Checkbox name="acceptConditions" label={t('mobilityRequest.sectionConfirmationText')} />
      </section>
    </>
  );
};

export default Fieldset;
