/* istanbul ignore file */
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowLeft } from '@assets/svg/icons';
import { BookingDetails } from '@common/interfaces';
import { Button } from '@components';
import '../BookingPanel.styles.scss';

const BookingInfo = ({ booking }: { booking: BookingDetails }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { agencyFk, firstName, lastName, pax, transferTypeDisplay, typeOfTripDisplay } = booking;

  const segments = pathname.split('/');
  let basePath = segments.slice(0, -1).join('/');

  if (pathname.includes('/edit-drives/')) basePath = segments.slice(0, -3).join('/');
  else if (pathname.includes('/booking-accept/')) basePath = segments.slice(0, -2).join('/');

  return (
    <div className="bp-info">
      <Button className="bp-info-btn-back" icon={<ArrowLeft />} link={basePath} linkStyle="icon" />
      <div className="bp-info-title">
        <h2>{`${typeOfTripDisplay} • ${transferTypeDisplay} transfer`}</h2>
        <span>{`${firstName} ${lastName}, ${agencyFk?.shortName} - ${pax} Pax`}</span>
      </div>
      <Button
        className="bp-info-details"
        link={`${basePath}/${booking.id}`}
        text={t('bookingDetails.details')}
        linkStyle="transparent"
      />
    </div>
  );
};

export default BookingInfo;
