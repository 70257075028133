import { type FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MainMenu } from '@ui-modules';
import { FacilityAverageRating, Loader } from '@components';
import { useFacility } from '@common/hooks';
import { DocumentsIcon, ManualsIcon, TrainingsIcon } from '@assets/img';
import {
  Booking,
  CarTracking,
  CustomerSatisfaction,
  Financials,
  Fleet,
  MobilityPlanner,
  MyMobility,
  ReportingArea,
} from '@assets/svg/icons';
import { DriverLogo } from '@assets/svg/logos';
import './FacilityMenuPage.styles.scss';

const FacilityMenuPage: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { agencyName, facility, isLoading } = useFacility();
  const { city, country, facilityName, fleetManagementUrl, vehicleTrackingSystem } = facility;

  const menuButtons = [
    {
      label: t('common.booking'),
      className: 'btn-booking',
      icon: <Booking />,
      onClick: () => navigate('bookings'),
    },
    {
      label: t('common.mobilityPlanner'),
      className: 'btn-mobility-planner',
      onClick: () => navigate('mobility-planner'),
      icon: <MobilityPlanner />,
    },
    {
      label: t('common.myMobility'),
      className: 'btn-my-mobility',
      icon: <MyMobility />,
      onClick: () => navigate('mobility-management'),
    },
    {
      label: t('common.financials'),
      className: 'btn-financials',
      icon: <Financials />,
      onClick: () => navigate('financials'),
    },
    {
      label: t('common.reportingArea'),
      className: 'btn-reporting-area',
      icon: <ReportingArea />,
      onClick: () => navigate('reporting-area'),
    },
    {
      label: t('common.customerSatisfaction'),
      className: 'btn-customer-satisfaction',
      icon: <CustomerSatisfaction />,
      onClick: () => navigate('feedbacks'),
    },
  ];

  if (fleetManagementUrl)
    menuButtons.push({
      label: t('common.fleetManagement'),
      className: 'btn-fleet-management',
      icon: <Fleet />,
      onClick: () => window.open(fleetManagementUrl),
    });

  if (vehicleTrackingSystem)
    menuButtons.push({
      label: t('common.vehicleTrackingSystem'),
      className: 'btn-vehicle-tracking',
      icon: <CarTracking />,
      onClick: () => window.open(vehicleTrackingSystem),
    });

  const menuSubButtons = useMemo(
    () => [
      {
        label: t('common.manuals'),
        className: 'btn-manuals',
        icon: <img src={ManualsIcon} alt="manuals" />,
        onClick: () => navigate('manuals', { state: { resource: 'manuals' } }),
      },
      {
        label: t('common.trainings'),
        className: 'btn-trainings',
        icon: <img src={TrainingsIcon} alt="trainings" />,
        onClick: () => navigate('trainings', { state: { resource: 'trainings' } }),
      },
      {
        label: t('common.documents'),
        className: 'btn-documents',
        icon: <img src={DocumentsIcon} alt="documents" />,
        onClick: () => navigate('documents', { state: { resource: 'documents' } }),
      },
    ],
    [navigate, t],
  );

  const menuTitle =
    city && !isLoading ? `${facilityName}, ${city}, ${country} | ${agencyName}` : '';

  return (
    <section className="facility-menu-page">
      <div className="facility-menu">
        <Loader color="white" spinning={isLoading}>
          <MainMenu
            logo={<DriverLogo />}
            menuButtons={menuButtons}
            subMenuButtons={menuSubButtons}
            title={menuTitle}
          />
        </Loader>

        <div className="facility-average-rating">
          <FacilityAverageRating />
        </div>
      </div>
    </section>
  );
};

export default FacilityMenuPage;
