/* istanbul ignore file */
import { addMinutes, format, isAfter, isBefore, isValid, setHours, setMinutes } from 'date-fns';
import { TIME_INTERVAL } from '@common/constants';
import { BFShuttleRoute } from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import { BFRoute } from '../types';
import { FormSchemaType } from '../schema/Booking.schema';

export const checkConflictingRoutes = (data: FormSchemaType): string | null => {
  const isRouteInThePast = isBefore(
    new Date(`${format(data?.routes[0].pickupDate!, DF.ApiDate)}T${data?.routes[0].pickupTime}`),
    new Date(),
  );

  if (isRouteInThePast) return 'The pickup time cannot be earlier than the current time';

  const hasOverlap = data?.routes.slice(1).some((route, idx) => {
    const prevRoute = data?.routes[idx];
    const prevEndTime = addMinutes(
      new Date(`${format(prevRoute.pickupDate!, DF.ApiDate)}T${prevRoute.pickupTime}`),
      15,
    );
    const currentStartTime = new Date(
      `${format(route.pickupDate!, DF.ApiDate)}T${route.pickupTime}`,
    );

    return currentStartTime < prevEndTime;
  });

  if (hasOverlap) return 'Check routes, interval cannot be less than 15 minutes';

  return null;
};

export const validateRouteTime = (
  eventTime: Date,
  eventRouteIdx: number,
  routes: BFRoute[],
): string | null => {
  if (eventRouteIdx === 0) {
    const isRouteInThePast = isBefore(eventTime, new Date());
    if (isRouteInThePast) return 'The pickup time cannot be earlier than the current time';
  }

  const startTimeRange = addMinutes(eventTime, -TIME_INTERVAL);
  const endTimeRange = addMinutes(eventTime, TIME_INTERVAL);

  if (!isValid(startTimeRange) || !isValid(endTimeRange)) {
    return 'Invalid time range';
  }

  const startTimeRangeString = format(startTimeRange, DF.DateFull);
  const endTimeRangeString = format(endTimeRange, DF.DateFull);

  let msg = null;

  routes.some((route, idx) => {
    if (idx === eventRouteIdx) return false;

    const pickupDateTimeString = `${format(route.pickupDate!, DF.ApiDate)} ${route.pickupTime}`;

    const isWithinTimeRange =
      pickupDateTimeString >= startTimeRangeString &&
      pickupDateTimeString <= endTimeRangeString &&
      pickupDateTimeString !== startTimeRangeString &&
      pickupDateTimeString !== endTimeRangeString;

    if (isWithinTimeRange) {
      msg = 'Interval between routes cannot be less than 15 minutes';
      return true;
    }

    return false;
  });

  if (eventRouteIdx > 0) {
    const previousRoute = routes[eventRouteIdx - 1];
    const previousTime = setMinutes(
      setHours(
        new Date(previousRoute.pickupDate!),
        parseInt(previousRoute.pickupTime.split(':')[0], 10),
      ),
      parseInt(previousRoute.pickupTime.split(':')[1], 10),
    );

    if (isBefore(eventTime, previousTime)) {
      msg = 'Current route cannot be earlier than the previous';
    }
  }

  if (eventRouteIdx < routes.length - 1) {
    const nextRoute = routes[eventRouteIdx + 1];

    if (nextRoute.pickupDate && nextRoute.pickupTime) {
      const nextTime = setMinutes(
        setHours(new Date(nextRoute.pickupDate), parseInt(nextRoute.pickupTime.split(':')[0], 10)),
        parseInt(nextRoute.pickupTime.split(':')[1], 10),
      );

      if (isAfter(eventTime, nextTime)) {
        msg = 'Current route cannot be later than the next';
      }
    }
  }

  return msg;
};

export const validRouteByDate = (date: Date, shuttleRoutes: BFShuttleRoute[]): BFShuttleRoute[] =>
  shuttleRoutes.filter((r) => {
    const dayOfWeek = date.getDay() - 1;
    const formattedDate = format(date, DF.ApiDate);

    const isDateInRange = date >= r.startDate && date <= r.endDate;
    const isExcludedDate = r.excludedDates?.includes(formattedDate);
    const isIncludedDate = r.includedDates?.includes(formattedDate);
    const isAllowedDayOfWeek = r.weekdays
      ? r.weekdays.includes(dayOfWeek === -1 ? 6 : dayOfWeek)
      : true;

    return isDateInRange && (isIncludedDate || (!isExcludedDate && isAllowedDayOfWeek));
  });
