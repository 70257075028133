import { type FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Button, Modal } from '@mantine/core';

import { DropdownOption } from '@common/interfaces';
import { DateFormat } from '@common/types';
import {
  BFDailyFrequency,
  BFPaxInfo,
  BFRecurringPeriod,
  BFRoute,
  BFState,
  BFTransferType,
  BFTripType,
  BookingFormType,
  BookingType,
} from '../../types';
import './RecapBooking.styles.scss';

interface RecapBookingProps {
  agencies: DropdownOption[];
  data: Record<string, any> | null;
  opened: boolean;
  purposes: DropdownOption[];
  units: DropdownOption[];
  onClose: () => void;
  onConfirm: () => void;
}

const RecapBooking: FC<RecapBookingProps> = ({
  agencies,
  data,
  opened,
  purposes,
  units,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const {
    addRecurring,
    agentDetails,
    bookingDetails,
    bookingExtra,
    bookingFormType,
    passengersInfo,
    recurring,
    routes,
    transferType,
    travellerDetails,
    typeOfTrip,
  } = (data || {}) as BFState;
  const isBFColleague = bookingFormType === BookingFormType.Colleague;
  const transferTypeDisplay = BFTransferType[transferType! as keyof typeof BFTransferType];
  const tripTypeDisplay = BFTripType[typeOfTrip! as keyof typeof BFTripType];
  const agency =
    agencies?.find((i) => i.value === (isBFColleague ? travellerDetails : bookingDetails)?.agency)
      ?.label || '';
  const purpose = purposes?.find((i) => i.value === bookingExtra?.purpose)?.label || '';
  const unit =
    units?.find(
      (i) => i.value === (isBFColleague ? travellerDetails : bookingExtra)?.requestingUnit,
    )?.label || '';

  const getFormattedDate = (date: Date) => format(date, DateFormat.ApiDateAlt);
  const getLabel = (key: string, prefix = 'common') => t(`${prefix}.${key}`);
  const getRecurringFrequencyLabel = (value: BFDailyFrequency): string => {
    switch (value) {
      case BFDailyFrequency.AllDays:
        return t('common.recurring.allDays');
      case BFDailyFrequency.WorkingDaysFromMonday:
        return t('common.recurring.workingDaysFromMonday');
      case BFDailyFrequency.WorkingDaysFromSunday:
        return t('common.recurring.workingDaysFromSunday');
      default:
        return '';
    }
  };
  const getRecurringPeriodLabel = (value: BFRecurringPeriod): string => {
    switch (value) {
      case BFRecurringPeriod.Daily:
        return t('common.daily');
      case BFRecurringPeriod.Weekly:
        return t('common.weekly');
      default:
        return '';
    }
  };

  const renderAttachments = () =>
    bookingDetails?.attachments?.length ? (
      <ul className="recap-booking-section">
        {bookingDetails.attachments.map((file: File, idx: number) => {
          const key = `${file.name}-${idx}`;
          return (
            <li className="attachment" key={key}>
              <div className="recap-item-label">
                {t('bookingForm.attachment')} {idx + 1}
              </div>
              <div className="recap-item-value">{file.name}</div>
            </li>
          );
        })}
      </ul>
    ) : null;

  const renderDetails = (
    obj: Record<string, any>,
    keys: (string | [string, string | Date])[],
    labelPrefix?: string,
  ) => (
    <ul className="recap-booking-section">
      {keys.map((key) => {
        const label = getLabel(typeof key === 'string' ? key : key[0], labelPrefix);
        const value = typeof key === 'string' ? obj[key] : key[1];
        const className =
          typeof key === 'string'
            ? key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
            : key[0].replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

        return (
          <li className={className} key={typeof key === 'string' ? key : key[0]}>
            <div className="recap-item-label">{label}</div>
            <div className="recap-item-value">
              {typeof value === 'object' && value instanceof Date ? getFormattedDate(value) : value}
            </div>
          </li>
        );
      })}
    </ul>
  );

  const renderAgentDetails = () =>
    isBFColleague && agentDetails ? (
      <>
        <h2>{t('bookingForm.agentDetails')}</h2>
        {renderDetails(agentDetails, ['firstName', 'lastName', 'email'])}
      </>
    ) : null;

  const renderExtraInfo = () =>
    bookingExtra ? (
      <>
        <h2>{t('bookingForm.extraInformation')}</h2>
        {renderDetails(
          bookingExtra,
          transferType === BookingType.Airport
            ? [
                isBFColleague
                  ? ['managerEmail', bookingExtra?.travellerManagerEmail || '']
                  : 'managerEmail',
                'flightNumber',
                'flightArrivalDepartureTime',
                'budgetCode',
                'remarks',
              ]
            : [
                isBFColleague
                  ? ['managerEmail', bookingExtra?.travellerManagerEmail || '']
                  : 'managerEmail',
                ['purpose', purpose!],
                'budgetCode',
                'remarks',
              ],
          'common',
        )}
      </>
    ) : null;

  const renderPassengers = () =>
    passengersInfo?.map((pax: BFPaxInfo, idx: number) => {
      const label = `${t('common.passenger')} ${idx + 2} details`;
      return (
        <Fragment key={label}>
          <h2>{label}</h2>
          {renderDetails(pax, ['firstName', 'lastName', 'email', 'agency'])}
        </Fragment>
      );
    });

  const renderRoutes = () =>
    routes?.map((route: BFRoute, idx: number) => {
      const label = `${t('bookingForm.drive')} ${idx + 1}`;
      return (
        <Fragment key={label}>
          <h2>{label}</h2>
          {renderDetails(
            route,
            [
              'pickupDate',
              'pickupTime',
              'pickupTown',
              'dropoffTown',
              'pickupLocation',
              'pickupLocExtra',
              'dropoffLocation',
              'dropoffLocExtra',
            ],
            'bookingDetails',
          )}
        </Fragment>
      );
    });

  const renderRecurringInfo = () =>
    addRecurring ? (
      <>
        <h2>{t('bookingForm.recurringDrive')}</h2>
        <ul className="recap-booking-section">
          <li>
            <div className="recap-item-label">{t('bookingForm.type')}</div>
            <div className="recap-item-value">
              {recurring?.recurringPeriod
                ? getRecurringPeriodLabel(recurring?.recurringPeriod)
                : null}
            </div>
          </li>
          <li>
            <div className="recap-item-label">{t('common.from')}</div>
            <div className="recap-item-value">{getFormattedDate(routes[0]?.pickupDate!)}</div>
          </li>
          <li>
            <div className="recap-item-label">{t('common.to')}</div>
            <div className="recap-item-value">
              {recurring?.recurringUntil ? getFormattedDate(recurring.recurringUntil) : null}
            </div>
          </li>
          <li>
            <div className="recap-item-label">{t('common.recurring.dailyFrequency')}</div>
            <div className="recap-item-value">
              {recurring?.recurringFrequency
                ? getRecurringFrequencyLabel(recurring.recurringFrequency)
                : null}
            </div>
          </li>
        </ul>
      </>
    ) : null;

  return (
    <Modal
      title={t('bookingForm.bookingRecap')}
      classNames={{
        content: 'modal-recap-booking',
        header: 'modal-recap-booking-header',
        body: 'modal-recap-booking-body',
      }}
      opened={opened}
      onClose={onClose}
      size={900}
    >
      <section className="recap-booking">
        <h2>
          <span>
            {t('common.transferType')}: {transferTypeDisplay}
          </span>
          {tripTypeDisplay ? (
            <span>
              {t('common.typeOfTrip')}: {tripTypeDisplay}
            </span>
          ) : null}
        </h2>

        {renderAgentDetails()}

        <h2>{t('bookingForm.details')}</h2>
        {isBFColleague &&
          travellerDetails &&
          renderDetails(travellerDetails, [
            'firstName',
            'lastName',
            'email',
            'phoneNumber',
            ['agency', agency],
            'indexNumber',
            ['requestingUnit', unit],
            ['pax', bookingExtra.pax!],
          ])}

        {!isBFColleague &&
          bookingDetails &&
          renderDetails(bookingDetails, [
            'firstName',
            'lastName',
            'email',
            'phoneNumber',
            ['agency', agency],
            'indexNumber',
            ['requestingUnit', unit],
            ['pax', bookingExtra.pax!],
          ])}

        {renderAttachments()}
        {renderRoutes()}
        {renderRecurringInfo()}
        {renderPassengers()}
        {renderExtraInfo()}
      </section>

      <footer>
        <Button type="button" onClick={onClose}>
          {t('bookingForm.btnGoBack')}
        </Button>
        <Button type="button" color="green" onClick={onConfirm}>
          {t('bookingForm.btnConfirmBooking')}
        </Button>
      </footer>
    </Modal>
  );
};

export default RecapBooking;
