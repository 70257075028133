import { type FC, useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { format, setHours, setMinutes } from 'date-fns';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebounce } from 'usehooks-ts';

import {
  BFSchemaApi,
  BFShuttleRoute,
  BFShuttleRouteApi,
  BookingCreateApi,
  BookingTripType,
  DropdownOption,
} from '@common/interfaces';
import { useAvailableLocations, useUserInfo } from '@common/hooks';
import { emailValidator, getErrors, getFormSubmitError, Serializer } from '@common/utils';
import { useRepository } from '@context';
import {
  Button,
  Checkbox,
  InputText,
  Loader,
  Panel,
  RadioGroup,
  SegmentControl,
} from '@components';
import { DriverLogo } from '@assets/svg/logos';
import { bookingFormSchema, type FormSchemaType } from './schema/Booking.schema';
import { Nav, RecapBooking } from './components';
import { BFRoute, BookingFormType, BookingType } from './types';
import {
  BFSerializer,
  checkConflictingRoutes,
  getFormTypes,
  getTransferTypes,
  getTripTypes,
  paxOptions,
  timeQuarter,
  validateRouteTime,
} from './utils';
import {
  Airport,
  Emergency,
  Main,
  Multileg,
  OneWay,
  PrivateHireForm,
  Recurring,
  RoundTrip,
  Shuttle,
  Success,
} from './sections';
import './BookingFormPage.styles.scss';

const BOOKING_FORM_URL = process.env.REACT_APP_BOOKING_FORM_URL;
let emailDomainPax: string = '';

const BookingFormPage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userInfo = useUserInfo();
  const { t } = useTranslation();
  const { id: facilityId } = useParams<{ id: string }>();
  const { accountRepository, agenciesRepository, bookingRepository, plannerRepo } = useRepository();
  const availableLocations = useAvailableLocations(facilityId);
  const { locationNotFound, locations, userLocation } = availableLocations || {};
  const { city, country, emergency, facilityName, isoCode } = userLocation || {};
  const isShuttleParam = new URLSearchParams(location.search).get('shuttle') === 'true';
  const isPrivateHireParam = new URLSearchParams(location.search).get('private-hire') === 'true';

  const [formType, setFormType] = useState<BookingType>();
  const [agencyOptions, setAgencyOptions] = useState<DropdownOption[]>([]);
  const [agencyTravellerOptions, setAgencyTravellerOptions] = useState<DropdownOption[]>([]);
  const [privateHireMode, setPrivateHireMode] = useState<boolean>(false);
  const [purposeOptions, setPurposeOptions] = useState<DropdownOption[]>([]);
  const [requestUnitOptions, setRequestUnitOptions] = useState<DropdownOption[]>([]);
  const [requestUnitTravellerOptions, setUnitTravellerOptions] = useState<DropdownOption[]>([]);
  const [recapBooking, setRecapBooking] = useState<Record<string, any> | null>(null);
  const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
  const [shuttleMode, setShuttleMode] = useState<boolean>(false);
  const [shuttleRoutes, setShuttleRoutes] = useState<BFShuttleRoute[]>([]);
  const [scheduledRoutes, setScheduledRoutes] = useState<BFShuttleRoute[]>([]);

  const initRoute = useMemo(
    (): BFRoute => ({
      dropoffLocation: '',
      dropoffLocExtra: '',
      dropoffTown: '',
      pickupDate: new Date(),
      pickupLocation: '',
      pickupLocExtra: '',
      pickupTime: '',
      pickupTown: city || '',
    }),
    [city],
  );

  const form = useForm<FormSchemaType>({
    defaultValues: {
      acceptConditions: true,
      addRecurring: false,
      bookingDetails: {
        agency: '',
        attachments: [],
        email: '',
        firstName: '',
        indexNumber: '',
        lastName: '',
        phoneNumber: '',
      },
      bookingExtra: {
        budgetCode: '',
        flightArrivalDepartureTime: '',
        flightNumber: '',
        managerEmail: '',
        pax: '',
        purpose: '',
        requestingUnit: '',
        remarks: '',
        travellerManagerEmail: '',
      },
      bookingFormType: BookingFormType.Self,
      passengersInfo: [],
      recurring: {
        recurringFrequency: '',
        recurringPeriod: '',
        recurringUntil: null,
      },
      routes: [initRoute],
      transferType: BookingType.Airport,
      typeOfTrip: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: zodResolver(bookingFormSchema),
  });

  const { control, handleSubmit, setValue, watch } = form;

  const bookingFormType$ = watch('bookingFormType');
  const debEmailPax$ = useDebounce(watch('travellerDetails.email')?.trim(), 800);
  const email$ = watch('bookingDetails.email');
  const fieldAgency$ = watch(debEmailPax$ ? 'travellerDetails.agency' : 'bookingDetails.agency');
  const hasRecurring$ = watch('addRecurring');
  const passengers$ = watch('passengersInfo');
  const pax$ = Number(watch('bookingExtra.pax')) - 1;
  const recurring$ = watch('recurring');
  const routes$ = watch('routes');
  const transferType$ = watch('transferType');
  const tripType$ = watch('typeOfTrip');

  const isBFColleague = bookingFormType$ === BookingFormType.Colleague;
  const isL3Emergency = emergency === 3;
  const isPrivateHire = isPrivateHireParam || privateHireMode;
  const isShuttle = transferType$ === BookingType.Shuttle;
  const noRoundTrip = isShuttle && !scheduledRoutes.length ? BookingTripType.RoundTrip : null;

  const { isLoading: isBookingCreating, mutate: createBooking } = useMutation(
    'create-booking',
    (data: BookingCreateApi) => bookingRepository.createBooking(facilityId!, data),
    {
      onSuccess: () => setRequestSubmitted(true),
      onError: (e: any) => {
        toast.error(getErrors(e.response.data));
      },
    },
  );

  const { isLoading: isAgencyLoading, mutateAsync: getAgencies } = useMutation(
    'get-agencies',
    (domain: string | undefined) =>
      agenciesRepository.getAgencies({ ...(domain ? { domain } : {}), limit: 250 }),
  );

  const { isLoading: isBFSchemaLoading } = useQuery(
    'get-schema',
    () => bookingRepository.getDriverBookingSchema(facilityId!),
    {
      enabled: !!facilityId && !!userLocation,
      onSuccess: (res: BFSchemaApi) => {
        const purpose = res?.booking_extra?.children?.purpose?.choices;
        const transferType = res?.driver_detail?.children?.transfer_type?.choices;

        if (purpose?.length) setPurposeOptions(purpose?.map(BFSerializer.formatChoices));
        if (transferType?.find((i) => i.value === BookingType.Shuttle)) {
          setShuttleMode(true);
        }
        if (transferType?.find((i) => i.value === BookingType.VehiclePrivateHire)) {
          setPrivateHireMode(true);
        }
      },
    },
  );

  const { isLoading: isRequestUnitLoading, mutateAsync: getRequestUnits } = useMutation(
    'get-request-units',
    (agency: string) => plannerRepo.getBookingRequestingUnits(facilityId!, { agency }),
    {
      onSuccess: (res) => {
        if (isBFColleague) {
          setUnitTravellerOptions(res);
          setValue('travellerDetails.requestingUnit', '');
        } else {
          setRequestUnitOptions(res);
        }
      },
    },
  );

  const { isLoading: isShuttleRoutesLoading, mutateAsync: getShuttleRoutes } = useMutation(
    'get-shuttle-routes',
    (agency: string) => bookingRepository.getShuttleRoutes(facilityId!, { agencyId: agency }),
    {
      onSuccess: (res: BFShuttleRouteApi[]) => {
        const allShuttleRoutes = res?.map(BFSerializer.formatShuttleRoute);
        const relatedRoutes = allShuttleRoutes.filter((i: BFShuttleRoute) => i.relatedScheduleId);
        setShuttleRoutes(allShuttleRoutes);
        setScheduledRoutes(relatedRoutes);
      },
    },
  );

  useEffect(() => {
    if (facilityId && locationNotFound) {
      navigate(`/${BOOKING_FORM_URL}/?facility=${facilityId}`, { replace: true });
    }
  }, [facilityId, locationNotFound, navigate]);

  useEffect(() => {
    if (isShuttleParam) {
      setValue('transferType', BookingType.Shuttle);
      setValue('typeOfTrip', BookingTripType.OneWay);
    }
  }, [isShuttleParam, setValue]);

  useEffect(() => {
    const fieldAgency = debEmailPax$ ? 'travellerDetails.agency' : 'bookingDetails.agency';
    const fieldEmail = debEmailPax$ || email$;

    const getAgenciesByDomain = async (domain?: string) => {
      try {
        const { results: agencies } = await getAgencies(domain);

        if (agencies?.length === 1 && agencies[0].domain === 'fallback-agency.org') {
          setValue(fieldAgency, '');
          getAgenciesByDomain();
        } else {
          const options = agencies.map(Serializer.formatAgencyOptions);

          setValue(fieldAgency, options?.length === 1 ? options[0].value : '');

          if (debEmailPax$) {
            setAgencyTravellerOptions(options);
          } else {
            setAgencyOptions(options);
          }
        }
      } catch (error: any) {
        if (error.response) {
          if (debEmailPax$) {
            setValue('travellerDetails.requestingUnit', '');
            setAgencyTravellerOptions([]);
            setUnitTravellerOptions([]);
          }
          setValue(fieldAgency, '');
          toast.error(getErrors(error.response.data));
        }
      }
    };

    if (userLocation && fieldEmail && typeof emailValidator(fieldEmail) !== 'string') {
      const domain = fieldEmail.split('@')[1].toLowerCase() || '';

      if (isBFColleague && debEmailPax$ && domain !== emailDomainPax) {
        emailDomainPax = domain;
        getAgenciesByDomain(domain);
      } else if (bookingFormType$ === BookingFormType.Self) {
        getAgenciesByDomain(domain);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debEmailPax$, email$, userLocation, getAgencies, setValue]);

  useEffect(() => {
    const fieldAgencyOptions = debEmailPax$ ? agencyTravellerOptions : agencyOptions;
    const fieldAgencyId = fieldAgencyOptions?.find((i) => i.value === fieldAgency$)?.value;
    if (fieldAgencyId) getRequestUnits(fieldAgencyId);
    if (fieldAgencyId && isShuttle) {
      getShuttleRoutes(fieldAgencyId);
      setValue('typeOfTrip', BookingTripType.OneWay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyOptions, agencyTravellerOptions, fieldAgency$, isShuttle]);

  useEffect(() => {
    if (userInfo) {
      const { email, firstName, index, lastName, phoneNumber, requestingUnit, supervisorEmail } =
        userInfo;
      setValue('bookingDetails.email', email);
      setValue('bookingDetails.firstName', firstName);
      setValue('bookingDetails.lastName', lastName);

      setValue('bookingDetails.indexNumber', index || '');
      setValue('bookingDetails.phoneNumber', phoneNumber || '');
      setValue('bookingExtra.requestingUnit', requestingUnit || '');
      setValue('bookingExtra.managerEmail', supervisorEmail || '');
    }

    if (city) {
      setValue('routes.0.pickupTime', isShuttle ? '' : format(timeQuarter(new Date()), 'HH:mm'));
      setValue('routes.0.dropoffTown', isShuttle ? '' : city);
      setValue('routes.0.pickupTown', isShuttle ? '' : city);
    }
  }, [city, isShuttle, userInfo, setValue]);

  useEffect(() => {
    if (isL3Emergency) {
      const passengers = pax$
        ? Array.from({ length: pax$ }, () => ({
            agency: '',
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            requestingUnit: '',
          }))
        : [];

      setValue('passengersInfo', passengers);
    }
  }, [isL3Emergency, pax$, setValue]);

  useEffect(() => {
    if (transferType$) setFormType(transferType$ as BookingType);

    if (transferType$ !== BookingType.Airport) {
      setValue('bookingExtra.flightArrivalDepartureTime', '');
      setValue('bookingExtra.flightNumber', '');
    }

    if (routes$) {
      let routes =
        tripType$ && tripType$ !== BookingTripType.OneWay && transferType$ !== BookingType.Airport
          ? [initRoute, initRoute]
          : [initRoute];

      if (tripType$ && transferType$ !== BookingType.Airport) {
        const pickupTown = watch('routes.0.pickupTown');

        routes = routes.map((route, idx) => ({
          ...route,
          dropoffTown: !isShuttle ? pickupTown : '',
          pickupDate: !isShuttle ? new Date() : null,
          pickupTown: !isShuttle ? pickupTown : '',
          pickupTime: !isShuttle && !idx ? format(timeQuarter(new Date()), 'HH:mm') : '',
        }));
      }

      setValue('routes', routes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferType$, tripType$]);

  useEffect(() => {
    if (hasRecurring$ && recurring$?.recurringUntil! < routes$[0]?.pickupDate!) {
      setValue('recurring.recurringUntil', null);
    }
  }, [hasRecurring$, recurring$?.recurringUntil, routes$, setValue]);

  const handleFormConfirm = async () => {
    const payload = await BFSerializer.mapFormDataToBookingCreate(recapBooking as FormSchemaType);
    setRecapBooking(null);
    createBooking(payload);
  };

  const handleFormSubmit = (data: FormSchemaType) => {
    const conflict = checkConflictingRoutes(data);
    if (conflict) return toast.error(conflict);

    setRecapBooking(data);
  };

  const handleFormSubmitError = (error: Record<string, any>) => {
    toast.error(getFormSubmitError(error));
  };

  const handleFormTypeChange = (type: BookingType) => {
    setFormType(type);
    setValue('transferType', type);
  };

  const handleTimeChange = (value: string, idx: number) => {
    const route = routes$[idx];

    if (value && route.pickupDate) {
      const [hours, minutes = '00'] = value.split(':');
      const time = setMinutes(
        setHours(new Date(route.pickupDate), parseInt(hours, 10)),
        parseInt(minutes, 10),
      );

      const msg = validateRouteTime(time, idx, routes$);

      if (msg) toast.error(msg);
    }
  };

  const handleDateChange = (idx: number) => {
    handleTimeChange(routes$[idx]?.pickupTime, idx);
  };

  const formTitle = useMemo(
    () => (
      <>
        <DriverLogo />
        {userLocation ? `${t('bookingForm.title')} ${city!}, ${country!} - ${facilityName}` : ''}
      </>
    ),
    [city, country, facilityName, t, userLocation],
  );

  const bookingFormTypes = getFormTypes(t);
  const transferTypes = getTransferTypes(isPrivateHire, isShuttleParam, shuttleMode, t);
  const tripTypeOptions = getTripTypes(isShuttle, t);

  const { data: flagData } = useQuery(
    'get-flags',
    () => accountRepository.getFeatureFlags({ facilityId }),
    { enabled: !!facilityId },
  );

  const { is_active: showGoogleLocation } = flagData?.flags?.showGoogleLocation || {};

  return (
    <>
      <Nav location={locations?.find((i) => i.id === +facilityId!)} />

      {!userLocation ? <Loader fullScreen spinning data-testid="booking-form-loader" /> : null}

      {userLocation && !isBFSchemaLoading && !requestSubmitted && (
        <section className="hbh-container booking-form-page">
          <Panel
            className="content-panel"
            isLoading={
              isAgencyLoading || isRequestUnitLoading || isShuttleRoutesLoading || isBookingCreating
            }
            title={formTitle}
          >
            {formType !== BookingType.VehiclePrivateHire ? (
              <form
                className="booking-form"
                onSubmit={handleSubmit(handleFormSubmit, handleFormSubmitError)}
              >
                <section>
                  <h2>{t('bookingForm.bookingType')}</h2>

                  <RadioGroup
                    name="bookingFormType"
                    className="field booking-form-type wide"
                    control={control}
                    defaultValue={BookingFormType.Self}
                    options={bookingFormTypes}
                    required
                    data-testid="bf-type"
                  />

                  <SegmentControl
                    name="transferType"
                    className="ctrl transfer-type"
                    color="lime"
                    control={control}
                    data={transferTypes}
                    disabled={transferTypes?.length === 1}
                    fullWidth
                  />
                </section>

                <Main
                  agencies={isBFColleague ? agencyTravellerOptions : agencyOptions}
                  ctrl={control}
                  isBFC={isBFColleague}
                  pax={paxOptions}
                  requestUnits={isBFColleague ? requestUnitTravellerOptions : requestUnitOptions}
                  userInfo={userInfo}
                />

                {isL3Emergency && passengers$.length > 0 && (
                  <Emergency
                    ctrl={control}
                    pax={passengers$.length}
                    requestUnits={isBFColleague ? requestUnitTravellerOptions : requestUnitOptions}
                  />
                )}

                <section>
                  <h2>{t('bookingForm.routeDetails')}</h2>

                  {transferType$ === BookingType.Airport ? (
                    <Airport
                      city={city}
                      country={`${country} [${isoCode}]`}
                      form={form}
                      showGoogleLocation={showGoogleLocation}
                    />
                  ) : (
                    <RadioGroup
                      name="typeOfTrip"
                      className="field wide"
                      control={control}
                      disabledOption={noRoundTrip}
                      label={t('bookingForm.selectTripType')}
                      options={tripTypeOptions}
                      required
                    />
                  )}

                  {transferType$ === BookingType.InTown && (
                    <>
                      {!showGoogleLocation && (
                        <section>
                          <InputText
                            name="routes.0.pickupTown"
                            className="field wide"
                            control={control}
                            label={t('common.town')}
                            required
                            onChange={(e) => {
                              setValue('routes.0.dropoffTown', e.target.value);
                            }}
                          />
                        </section>
                      )}

                      {tripType$ === BookingTripType.OneWay && (
                        <OneWay
                          city={city}
                          country={`${country} [${isoCode}]`}
                          form={form}
                          purpose={purposeOptions}
                          showGoogleLocation={showGoogleLocation}
                        />
                      )}

                      {tripType$ === BookingTripType.RoundTrip && (
                        <RoundTrip
                          city={city}
                          country={`${country} [${isoCode}]`}
                          form={form}
                          purpose={purposeOptions}
                          routes={routes$}
                          showGoogleLocation={showGoogleLocation}
                          onDateChange={handleDateChange}
                          onTimeChange={handleTimeChange}
                        />
                      )}

                      {tripType$ === BookingTripType.MultiLeg && (
                        <Multileg
                          city={city}
                          country={`${country} [${isoCode}]`}
                          form={form}
                          init={{ ...initRoute, dropoffTown: watch('routes.0.pickupTown') }}
                          purpose={purposeOptions}
                          showGoogleLocation={showGoogleLocation}
                          onDateChange={handleDateChange}
                          onTimeChange={handleTimeChange}
                        />
                      )}
                    </>
                  )}

                  {transferType$ === BookingType.OutTown && (
                    <>
                      {tripType$ === BookingTripType.OneWay && (
                        <OneWay
                          city={city}
                          country={`${country} [${isoCode}]`}
                          form={form}
                          purpose={purposeOptions}
                          showGoogleLocation={showGoogleLocation}
                          showTown={!showGoogleLocation}
                        />
                      )}

                      {tripType$ === BookingTripType.RoundTrip && (
                        <RoundTrip
                          city={city}
                          country={`${country} [${isoCode}]`}
                          form={form}
                          purpose={purposeOptions}
                          routes={routes$}
                          showGoogleLocation={showGoogleLocation}
                          showTown={!showGoogleLocation}
                          onDateChange={handleDateChange}
                          onTimeChange={handleTimeChange}
                        />
                      )}

                      {tripType$ === BookingTripType.MultiLeg && (
                        <Multileg
                          city={city}
                          country={`${country} [${isoCode}]`}
                          form={form}
                          init={initRoute}
                          purpose={purposeOptions}
                          showGoogleLocation={showGoogleLocation}
                          showTown={!showGoogleLocation}
                          onDateChange={handleDateChange}
                          onTimeChange={handleTimeChange}
                        />
                      )}
                    </>
                  )}

                  {transferType$ === BookingType.Shuttle && (
                    <Shuttle
                      form={form}
                      isRoundTrip={tripType$ === BookingTripType.RoundTrip}
                      purpose={purposeOptions}
                      routes={routes$}
                      shuttleRoutes={shuttleRoutes}
                    />
                  )}
                </section>

                {!isShuttle && (
                  <section className="recurring">
                    {hasRecurring$ && (
                      <Recurring
                        ctrl={control}
                        currentDate={routes$[0].pickupDate || new Date()}
                        recurring={recurring$}
                      />
                    )}

                    <Button
                      className="btn btn-recurring"
                      color="green"
                      text={
                        hasRecurring$
                          ? t('common.recurring.cancelRecurringBtn')
                          : t('common.recurring.addRecurringBtn')
                      }
                      onClick={() => {
                        setValue('addRecurring', !hasRecurring$);
                      }}
                    />
                  </section>
                )}

                <footer>
                  <Checkbox
                    name="acceptConditions"
                    control={control}
                    label={
                      <>
                        <span>I&apos;ve read and accept the Terms and Conditions*</span>
                        <br />
                        <span>
                          <a
                            href={`${process.env.REACT_APP_TERMS_LINK}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            Click here
                          </a>
                          &nbsp; to open in a new window
                        </span>
                      </>
                    }
                    required
                  />
                  <Button
                    disabled={!watch('acceptConditions')}
                    text={t('bookingForm.requestYourBooking')}
                    type="submit"
                  />
                </footer>
              </form>
            ) : (
              <PrivateHireForm
                transferTypes={transferTypes}
                userLocation={userLocation}
                onFormTypeChange={handleFormTypeChange}
              />
            )}
          </Panel>

          <RecapBooking
            agencies={[...agencyOptions, ...agencyTravellerOptions]}
            data={recapBooking}
            opened={!!recapBooking}
            purposes={purposeOptions}
            units={[...requestUnitOptions, ...requestUnitTravellerOptions]}
            onClose={() => {
              setRecapBooking(null);
            }}
            onConfirm={handleFormConfirm}
          />
        </section>
      )}

      {requestSubmitted && (
        <Success city={city!} email={email$} isoCode={userLocation?.isoCode!} title={formTitle} />
      )}
    </>
  );
};

export default BookingFormPage;
