import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  AvailableLocations,
  DropdownOption,
  MobilityRequestsType,
  MROptionsApi,
  PrivateVehicleCreateApi,
} from '@common/interfaces';
import { getErrors, getFormSubmitError, Serializer } from '@common/utils';
import { Button, Field, Form, Loader } from '@components';
import { useRepository } from '@context';

import PrivateHireSchema, { PrivateHireFormType } from '../../schema/PrivateHire.schema';
import { Fieldset, Success } from './components';
import { BookingFormType, BookingType, TransferTypeOption } from '../../types';
import { BFSerializer, getFormTypes } from '../../utils';
import './PrivateHireForm.styles.scss';

interface PrivateHireFormProps {
  transferTypes: TransferTypeOption[];
  userLocation: AvailableLocations;
  onFormTypeChange: (e: BookingType) => void;
}

const PrivateHireForm = ({
  transferTypes,
  userLocation,
  onFormTypeChange,
}: PrivateHireFormProps) => {
  const { t } = useTranslation();
  const { mobilityRequestsRepo } = useRepository();

  const [options, setOptions] = useState<Record<string, DropdownOption[]> | null>(null);
  const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);

  const { isLoading: isRequestCreating, mutate: createPrivateVehicle } = useMutation(
    'create-booking',
    (data: PrivateVehicleCreateApi) => mobilityRequestsRepo.createPrivateVehicle(data),
    {
      onSuccess: () => setRequestSubmitted(true),
      onError: (e: any) => {
        toast.error(getErrors(e.response.data));
      },
    },
  );

  const { isLoading: isMobilityOptionsLoading } = useQuery(
    'get-mobility-requests-options',
    () => mobilityRequestsRepo.getMobilityRequestsOptions(),
    {
      onSuccess: (res: MROptionsApi[]) => {
        const data = res.find((i) => i.name === MobilityRequestsType.PRIVATE_VEHICLE);

        if (data) {
          const transOptions = Object.entries(data.options).reduce((acc, [key, values]) => {
            acc[Serializer.formatCamelCase(key)] = values.map((value) => ({ label: value, value }));

            return acc;
          }, {} as Record<string, DropdownOption[]>);

          setOptions(transOptions);
        }
      },
    },
  );

  const handleFormSubmit = useCallback(
    async (data: PrivateHireFormType) => {
      const payload = await BFSerializer.mapFormDataToPrivateVehicleCreate(data);

      createPrivateVehicle(payload);
    },
    [createPrivateVehicle],
  );

  const handleFormSubmitError = (error: Record<string, any>) => {
    toast.error(getFormSubmitError(error));
  };

  const formTypes = getFormTypes(t);
  const schema = useMemo(() => PrivateHireSchema(), []);

  const initFormData = useMemo(
    () => ({
      acceptConditions: true,
      agency: '',
      bookingFormType: BookingFormType.Self,
      contactEmail: [],
      contractType: '',
      country: userLocation.country,
      dateFrom: undefined,
      dateTo: undefined,
      dutyStation: userLocation.facilityName,
      profileEmail: '',
      entryOnDuty: undefined,
      firstName: '',
      isDriverNeeded: false,
      lastName: '',
      notToExceed: undefined,
      position: '',
      preferredTypeOfVehicle: '',
      purpose: '',
      reason: '',
    }),
    [userLocation],
  );

  return (
    <section className="vehicle-private-hire">
      <Loader fullScreen spinning={isMobilityOptionsLoading || isRequestCreating} />

      <Form
        className="form-private-hire"
        defaultValues={initFormData}
        schema={schema}
        theme="dark"
        onSubmit={handleFormSubmit}
        onError={handleFormSubmitError}
      >
        {({ setValue, watch }) => (
          <>
            <section>
              <h2>{t('bookingForm.bookingType')}</h2>

              <Field
                name="bookingFormType"
                input="radio"
                className="booking-form-type wide"
                defaultValue={BookingFormType.Self}
                options={formTypes}
              />

              <Field
                name="transferType"
                input="segmented-control"
                className="transfer-type"
                color="lime"
                data={transferTypes}
                disabled={transferTypes?.length === 1}
                fullWidth
                value={BookingType.VehiclePrivateHire}
                onChange={onFormTypeChange}
              />
            </section>

            <Fieldset options={options} setValue={setValue} watch={watch} />

            <footer>
              <Button
                className="btn-submit"
                disabled={!watch('acceptConditions')}
                text={t('mobilityRequest.submit')}
                type="submit"
              />
            </footer>
          </>
        )}
      </Form>

      {requestSubmitted && <Success />}
    </section>
  );
};

export default PrivateHireForm;
