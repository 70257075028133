import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import {
  add,
  addDays,
  addMinutes,
  isAfter,
  isEqual,
  isMatch,
  isSameDay,
  isSameMinute,
  parse,
  set,
} from 'date-fns';

import { Info } from '@assets/svg/icons';
import { TIME_INTERVAL } from '@common/constants';
import {
  DropdownOption,
  FeatureFlags,
  PlAcceptDrive,
  PlDrive,
  PlDriver,
  PlResource,
  PlVehicle,
  TransferType,
} from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import { getFullDate } from '@common/utils';
import { Field, Tooltip } from '@components';
import { useGlobalContext } from '@context';
import { DriveFormType } from '../../../schema/DriveForm.schema';
import { mapDriverOptions, mapVehicleOptions } from '../../../utils';

type Limits = { minDate: Date | undefined; minTime: Date | undefined; maxTime: Date | undefined };
type TimeProps = { pickupDate?: Date; pickupTime?: Date; dropoffDate?: Date; dropoffTime?: Date };

const initLimits = { minDate: undefined, minTime: undefined, maxTime: undefined };
const maxTime = parse('23:45', DF.ApiTime, new Date());

interface FieldsetProps {
  acceptDrives?: PlAcceptDrive[];
  disabled?: boolean;
  drivers: PlDriver[];
  drives: PlDrive[];
  leg: number;
  resource?: [PlResource | null, Dispatch<SetStateAction<PlResource | null>>];
  vehicles: PlVehicle[];
  setValue: UseFormSetValue<DriveFormType>;
  watch: UseFormWatch<DriveFormType>;
}

const AcceptDriveFieldset = ({
  acceptDrives,
  disabled,
  drivers,
  drives,
  leg,
  resource,
  vehicles,
  setValue,
  watch,
}: FieldsetProps) => {
  const { t } = useTranslation();
  const { featureFlags } = useGlobalContext();

  const [pickupLimit, setPickLimit] = useState<Limits>(initLimits);
  const [dropoffLimit, setDropLimit] = useState<Limits>(initLimits);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [warning, setWarning] = useState<string | null>(null);

  const data = acceptDrives?.[leg - 1];
  const isShuttle = data?.transferType === TransferType.Shuttle;
  const showGoogleLocation = (featureFlags as FeatureFlags)?.flags?.showGoogleLocation?.is_active;
  const [scheduledResource, setScheduledResource] = resource || [];

  const [driver$, dropoffDate$, dropoffTime$, pickupDate$, pickupTime$, vehicle$] = watch([
    'driver',
    'dropoffDate',
    'dropoffTime',
    'pickupDate',
    'pickupTime',
    'vehicle',
  ]);

  const driverOptions: DropdownOption[] = useMemo(() => mapDriverOptions(drivers), [drivers]);
  const vehicleOptions: DropdownOption[] = useMemo(() => mapVehicleOptions(vehicles), [vehicles]);

  const handleVehicleChange = useCallback((value: string) => {
    if (value) {
      const maxCapacity = vehicles.find((i) => i.id === value)?.maxCapacity;
      const pax = data?.passengerPax;
      if (pax && maxCapacity) {
        const msg = `${t('mobility.vehiclePaxWarning')} (Capacity: ${maxCapacity}; Pax: ${pax})`;
        setWarning(pax > maxCapacity ? msg : null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateDateTimeConstraints = (e: TimeProps) => {
    const pickDate = e.pickupDate || pickupDate$;
    const pickTime = e.pickupTime || pickupTime$;
    const dropDate = e.dropoffDate || dropoffDate$;
    const dropTime = e.dropoffTime || dropoffTime$;

    if (!pickDate && !pickTime && !dropDate) return;

    const addInt = addMinutes(pickTime, TIME_INTERVAL);
    const isSameDate = isSameDay(pickDate, dropDate);

    setDropLimit((s) => ({ ...s, minDate: pickDate }));

    setDropLimit((s) => ({
      ...s,
      minDate: pickDate,
      ...(isSameDate && { maxTime, minTime: addInt }),
    }));

    // set dropoffDate
    if (dropDate && isAfter(pickDate, dropDate)) setValue('dropoffDate', pickDate);

    if (dropTime) {
      const start = getFullDate(pickDate, pickTime);
      const end = getFullDate(dropDate, dropTime);

      const isPickupGreater =
        (isSameDate || isAfter(pickDate, dropDate)) &&
        (isEqual(pickTime, dropTime) || isAfter(pickTime, dropTime));
      const isNextDay =
        isSameMinute(pickTime, maxTime) && (isEqual(start, end) || isAfter(start, end));

      if (isPickupGreater) setValue('dropoffTime', addInt);
      if (isPickupGreater && isNextDay) setValue('dropoffDate', addDays(pickDate, 1));
    }
  };

  useEffect(() => {
    if (!acceptDrives?.length) return;

    const { dropoffDate, dropoffTime, isScheduled, pickupDate, pickupTime } =
      acceptDrives?.[leg - 1] || {};
    const dropoff = dropoffDate ? parse(dropoffDate, DF.ApiDate, new Date()) : null;
    const pickup = pickupDate ? parse(pickupDate, DF.ApiDate, new Date()) : null;

    if (pickup && !dropoffLimit.minDate) setDropLimit((s) => ({ ...s, minDate: pickup }));

    if (leg > 1) {
      const prevLeg = acceptDrives[leg - 2];
      const prevDropDate = parse(prevLeg.dropoffDate, DF.ApiDate, new Date());
      const prevDropTime = parse(prevLeg.dropoffTime, DF.ApiTime, new Date());
      const isSameDate = pickupDate ? isSameDay(prevDropDate, pickupDate$) : false;

      setPickLimit((s) => ({ ...s, minDate: prevDropDate }));

      if (isSameDate) {
        setPickLimit((s) => ({ ...s, maxTime, minTime: prevDropTime }));

        if (isAfter(prevDropTime, pickupTime$)) {
          setValue('pickupTime', prevDropTime);

          if (isAfter(prevDropTime, dropoffTime$) || isSameMinute(prevDropTime, dropoffTime$)) {
            setValue('dropoffTime', addMinutes(prevDropTime, TIME_INTERVAL));
          }
        }
      } else if (pickup && isAfter(prevDropDate, pickup)) {
        setValue('pickupDate', prevDropDate);
        setValue('pickupTime', prevDropTime);
        setValue('dropoffDate', prevDropDate);
        setPickLimit((s) => ({ ...s, maxTime, minTime: prevDropTime }));
      } else {
        setPickLimit((s) => ({ ...s, maxTime: undefined, minTime: undefined }));
      }
    }

    if (isScheduled) {
      if (pickup) setValue('pickupDate', pickup);
      if (dropoff) setValue('dropoffDate', dropoff);
      if (pickupTime) setValue('pickupTime', parse(pickupTime, DF.ApiTime, new Date()));
      if (dropoffTime) setValue('dropoffTime', parse(dropoffTime, DF.ApiTime, new Date()));
      if (data?.vehicleId) setValue('vehicle', data?.vehicleId);
      if (data?.driverId) setValue('driver', data?.driverId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptDrives, leg, setValue]);

  useEffect(() => {
    if (pickupDate$ && dropoffDate$ && isSameDay(pickupDate$, dropoffDate$)) {
      if (
        !data?.extra?.estimatedDuration &&
        (isAfter(pickupTime$, dropoffTime$) || isSameMinute(dropoffTime$, pickupTime$))
      ) {
        setValue('dropoffTime', addMinutes(pickupTime$, TIME_INTERVAL));
      }

      setDropLimit((s) => ({ ...s, maxTime, minTime: addMinutes(pickupTime$, TIME_INTERVAL) }));
    } else {
      setDropLimit((s) => ({ ...s, maxTime: undefined, minTime: undefined }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupDate$, dropoffDate$]);

  useEffect(() => {
    if (
      showGoogleLocation &&
      data?.extra?.estimatedDuration &&
      !isShuttle &&
      !data?.isScheduled &&
      isMatch(data?.extra?.estimatedDuration!, DF.ApiTimeFull)
    ) {
      const pickupDateTime = set(pickupDate$, {
        hours: pickupTime$.getHours(),
        minutes: pickupTime$.getMinutes(),
        seconds: 0,
        milliseconds: 0,
      });

      const [hours, minutes] = data.extra.estimatedDuration.split(':').map(Number);
      const dropoffEstimated = add(pickupDateTime, { hours, minutes });

      if (dropoffEstimated) {
        setValue('dropoffDate', dropoffEstimated);
        setValue('dropoffTime', dropoffEstimated);
        setShowTooltip(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.extra?.estimatedDuration, featureFlags, pickupTime$]);

  useEffect(() => {
    const preferredVehicle = drivers?.find((i) => i.id === driver$)?.preferredVehicle;
    if (preferredVehicle && !isShuttle) setValue('vehicle', preferredVehicle);
  }, [driver$, drivers, isShuttle, setValue]);

  useEffect(() => {
    const preferredDriver = vehicles?.find((i: PlVehicle) => i.id === vehicle$)?.preferredDriver;
    if (preferredDriver && !isShuttle) setValue('driver', preferredDriver);
  }, [isShuttle, setValue, vehicle$, vehicles]);

  useEffect(() => {
    if (scheduledResource) {
      setValue('vehicle', scheduledResource?.vehicleId!);
      setValue('driver', scheduledResource?.driverId!);
      setScheduledResource?.(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduledResource]);

  useEffect(() => {
    if (data && isShuttle) {
      const shuttleConfig = data?.scheduledRouteId
        ? drives.find(
            (i) =>
              i.bookingTransferType === TransferType.Shuttle &&
              i.scheduledRouteId === data?.scheduledRouteId,
          )
        : null;

      if (shuttleConfig) {
        setValue('vehicle', shuttleConfig?.vehicleId);
        setValue('driver', shuttleConfig?.driverId);
      }
    }
  }, [acceptDrives, data, drives, isShuttle, setValue]);

  return (
    <fieldset className="bp-leg-form-fieldset">
      <section className="bp-leg-form-fieldset-row">
        <Field
          name="pickupDate"
          input="date-picker"
          disabled={disabled}
          hideOutsideDates
          label={t('bookingDetails.pickupDate')}
          minDate={pickupLimit.minDate}
          valueFormat="DD.MM.YYYY"
          variant="unstyled"
          onChange={(pickupDate: Date) => updateDateTimeConstraints({ pickupDate })}
        />
        <Field
          name="pickupTime"
          input="time-picker"
          disabled={disabled}
          label={t('bookingDetails.pickupTime')}
          maxTime={pickupLimit.maxTime}
          minTime={pickupLimit.minTime}
          valueTypeDate
          variant="unstyled"
          onChange={(pickupTime: Date) => updateDateTimeConstraints({ pickupTime })}
        />
        <Field
          name="dropoffDate"
          input="date-picker"
          disabled={disabled}
          hideOutsideDates
          label={t('bookingDetails.dropoffDate')}
          minDate={dropoffLimit.minDate}
          valueFormat="DD.MM.YYYY"
          variant="unstyled"
          onChange={(dropoffDate: Date) => updateDateTimeConstraints({ dropoffDate })}
        />
        <Field
          name="dropoffTime"
          input="time-picker"
          defaultValue={isShuttle ? parse(data?.dropoffTime, DF.ApiTime, new Date()) : undefined}
          disabled={disabled}
          label={
            showTooltip ? (
              <Tooltip label={t('bookingDetails.estimatedByGoogle') as string} width={200}>
                <>
                  {t('bookingDetails.dropoffTime')}&nbsp;
                  <Info />
                </>
              </Tooltip>
            ) : (
              t('bookingDetails.dropoffTime')
            )
          }
          placeholder={t('bookingDetails.chooseTime')}
          maxTime={dropoffLimit.maxTime}
          minTime={dropoffLimit.minTime}
          valueTypeDate
          variant="unstyled"
          onChange={(dropoffTime: Date) => updateDateTimeConstraints({ dropoffTime })}
          data-testid={`planner-booking-panel-dropoff-time-${leg}`}
        />
        <Field
          name="vehicle"
          input="dropdown"
          description={<div className="field-info">{warning}</div>}
          disabled={disabled}
          label={t('mobility.selectVehicle')}
          options={vehicleOptions}
          placeholder={`${t('common.select')}...`}
          variant="unstyled"
          onChange={handleVehicleChange}
          data-testid={`planner-booking-panel-vehicle-${leg}`}
        />
        <Field
          name="driver"
          input="dropdown"
          disabled={disabled}
          label={t('mobility.selectDriver')}
          options={driverOptions}
          placeholder={`${t('common.select')}...`}
          variant="unstyled"
        />
      </section>
      <section className="bp-leg-form-fieldset-row">
        <Field
          name="commentPax"
          input="textarea"
          disabled={disabled}
          label={t('planner.commentPax')}
          placeholder="(optional)"
          variant="unstyled"
        />
        <Field
          name="commentDriver"
          input="textarea"
          disabled={disabled}
          label={t('planner.commentDriver')}
          placeholder="(optional)"
          variant="unstyled"
        />
      </section>
    </fieldset>
  );
};

export default AcceptDriveFieldset;
