/* istanbul ignore file */
/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import { Multistep, useFormState, useMultistepApi } from 'informed';
import { format } from 'date-fns';

import {
  Drive,
  DriveFormState,
  DriveFormSteps,
  DropdownOption,
  EventMoved,
  TransferType,
} from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import { Button } from '@components';
import { ArrowRight } from '@assets/svg/icons';
import { formatDateString } from '@common/utils';
import { findOption } from '../../../utils';
import '../../../styles/modal.scss';

interface StepProps {
  drive?: Drive;
  drivers: DropdownOption[];
  vehicles: DropdownOption[];
}

const ConfirmStep = ({ drive, drivers, vehicles }: StepProps) => {
  const { t } = useTranslation();
  const { setCurrent } = useMultistepApi();
  const { values } = useFormState() as unknown as { values: DriveFormState };

  const data = values?.tripInfo?.driveList?.[0];
  const showOrigin =
    drive?.eventMoved !== EventMoved.InShuttle && drive?.eventMoved !== EventMoved.OutOfShuttle;
  const transferTypeText =
    values?.tripInfo?.transferType === TransferType.Shuttle
      ? t('common.inTown')
      : t('common.transferTypeShuttle');

  const formatDate = (date?: string | Date, time?: string | Date) => {
    if (!date || !time) return '';
    return (
      <>
        {typeof date === 'string'
          ? formatDateString(date, DF.ApiDateAlt, DF.BookingInfoDate)
          : date
          ? format(date, DF.BookingInfoDate)
          : ''}{' '}
        {typeof time === 'string'
          ? formatDateString(time, DF.ApiTimeFull, DF.ApiTime)
          : time
          ? format(time, DF.ApiTime)
          : ''}
      </>
    );
  };

  const recapItem = (label: string, prev?: JSX.Element | string, next?: JSX.Element | string) => (
    <li>
      <span>{label}: </span>
      {!showOrigin ? (
        next
      ) : (
        <>
          {prev} <ArrowRight /> {next}
        </>
      )}
    </li>
  );

  return (
    <Multistep.Step step={DriveFormSteps.Confirm}>
      <section className="confirm">
        <div className="warning">
          {t('planner.warnShuttleChange')} {transferTypeText}
        </div>

        {data && drive && (
          <ul className="recap">
            {recapItem(
              'Pick-up',
              formatDate(drive.pickupDate, drive.pickupTime),
              formatDate(data.pickupDate, data.pickupTime),
            )}
            {recapItem(
              'Drop-off',
              formatDate(drive.dropoffDate, drive.dropoffTime),
              formatDate(data.dropoffDate, data.dropoffTime),
            )}
            {recapItem('Driver', findOption(drivers!, drive.driver!)?.label, data?.driver?.label)}
            {recapItem(
              'Vehicle',
              findOption(vehicles!, drive.vehicle!)?.label,
              data?.vehicle?.label,
            )}
          </ul>
        )}

        <footer>
          <Button
            text={t('common.no')}
            variant="danger"
            onClick={() => setCurrent(DriveFormSteps.Trip)}
          />
          <Button text={t('common.yes')} type="submit" variant="submit" />
        </footer>
      </section>
    </Multistep.Step>
  );
};

export default ConfirmStep;
