/* istanbul ignore file */
import { t } from 'i18next';
import * as yup from 'yup';

type PrivateHireFormType = yup.InferType<ReturnType<typeof PrivateHireSchema>>;

const PrivateHireSchema = () =>
  yup.object({
    acceptConditions: yup.boolean().required(),
    agency: yup.string().required(t('common.fieldRequired')),
    bookingFormType: yup.string().required(),
    contactEmail: yup
      .array()
      .of(yup.string().email(t('common.emailError')).required(t('common.fieldRequired')))
      .required(t('common.fieldRequired')),
    contractType: yup.string().required(t('common.fieldRequired')),
    country: yup.string().required(t('common.fieldRequired')),
    dateFrom: yup.date().required(t('common.fieldRequired')),
    dateTo: yup.date().required(t('common.fieldRequired')),
    dutyStation: yup.string().required(t('common.fieldRequired')),
    firstName: yup.string().max(255).required(t('common.fieldRequired')),
    isDriverNeeded: yup.boolean().optional(),
    lastName: yup.string().max(255).required(t('common.fieldRequired')),
    position: yup.string().max(255).required(t('common.fieldRequired')),
    preferredTypeOfVehicle: yup.string().required(t('common.fieldRequired')),
    profileEmail: yup.string().email(t('common.emailError')).required(t('common.fieldRequired')),
    purpose: yup.string().required(t('common.fieldRequired')),
    reason: yup.string().max(300).required(t('common.fieldRequired')),
    entryOnDuty: yup.date().required(t('common.fieldRequired')),
    notToExceed: yup.date().required(t('common.fieldRequired')),
    transferType: yup.string(),
  });

export default PrivateHireSchema;
export type { PrivateHireFormType };
