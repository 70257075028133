import { Crumb } from '@common/interfaces';
import {
  AvailableLocationsPage,
  BookingDetailsPage,
  BookingFormPage,
  BookingsPage,
  ChecklistDetailsPage,
  CostRecoveryReportPage,
  CountryReportPage,
  CreateAgencyInvoicePage,
  CreateIndividualInvoicePage,
  DashboardPage,
  DriverListPage,
  EntryPage,
  FacilityMenuPage,
  FeedbacksPage,
  FinancialsMenuPage,
  IndividualInvoiceListPage,
  InvoiceHistoryPage,
  MobilityLogbookDetailsPage,
  MobilityLogbookPage,
  MobilityMenuPage,
  MobilityPlannerPage,
  ReportingAreaMenuPage,
  ResourcesPage,
  ServiceCostSettingsPage,
  TravelRequestList,
  TravelRequestTripEdit,
  TravelRequestProfile,
  VehicleListPage,
  VehiclesChecklistPage,
} from '@pages';

import bgImg from '@assets/img/default/background.jpg';
import bgChecklist from '@assets/img/default/bg_checklist_report_page.jpeg';

interface RouteElement extends Crumb {
  children?: Crumb[];
}

const routesSchema = [
  // Booking Form
  {
    path: '/',
    Component: AvailableLocationsPage,
    bgImg,
  },
  {
    path: '/booking-form/:id',
    Component: BookingFormPage,
    bgImg,
  },
  // Digital Office
  {
    path: '/secure/office',
    Component: EntryPage,
    bgImg,
  },
  {
    path: '/secure/office/facility/:facilityId/:agencyName/:agencyId',
    Component: FacilityMenuPage,
    bgImg,
    name: 'crumbs.facilityMenu',
    showNav: true,
    children: [
      {
        path: '/bookings',
        Component: BookingsPage,
        bgImg,
        name: 'crumbs.bookingList',
        showNav: true,
        children: [
          {
            path: '/:id',
            Component: BookingDetailsPage,
            bgImg,
            name: 'crumbs.bookingDetails',
            showNav: true,
          },
          {
            path: '/:id/edit-drives/:driveId',
            Component: MobilityPlannerPage,
            bgImg,
            name: 'common.mobilityPlanner',
            showNav: true,
          },
          {
            path: '/booking-accept/:id',
            Component: MobilityPlannerPage,
            bgImg,
            name: 'common.mobilityPlanner',
            showNav: true,
          },
        ],
      },
      {
        path: '/feedbacks',
        Component: FeedbacksPage,
        name: 'common.customerSatisfaction',
        showNav: true,
      },
      {
        path: '/financials',
        Component: FinancialsMenuPage,
        bgImg,
        name: 'common.financials',
        showNav: true,
        children: [
          {
            path: '/cost-recovery-report',
            Component: CostRecoveryReportPage,
            bgImg,
            name: 'common.costRecoveryReport',
            showNav: true,
          },
          {
            path: '/cost-recovery-report/invoice/:id',
            Component: CreateAgencyInvoicePage,
            bgImg,
            name: 'crumbs.createAgencyInvoice',
            showNav: true,
          },
          {
            path: '/create-invoice-list',
            Component: IndividualInvoiceListPage,
            bgImg,
            name: 'crumbs.bookings',
            showNav: true,
          },
          {
            path: '/create-invoice-list/:id',
            Component: CreateIndividualInvoicePage,
            bgImg,
            name: 'invoice.createInvoice',
            showNav: true,
          },
          {
            path: '/history-invoice-list',
            Component: InvoiceHistoryPage,
            bgImg,
            name: 'invoice.createInvoice',
            showNav: true,
          },
          {
            path: '/service-cost-setting',
            Component: ServiceCostSettingsPage,
            bgImg,
            name: 'common.serviceCostSettings',
            showNav: true,
          },
        ],
      },
      {
        path: '/mobility-management',
        Component: MobilityMenuPage,
        bgImg,
        name: 'common.myMobility',
        showNav: true,
        children: [
          {
            path: '/driver-list',
            Component: DriverListPage,
            name: 'mobility.myDrivers',
            showNav: true,
          },
          {
            path: '/logbook',
            Component: MobilityLogbookPage,
            name: 'common.mobilityLogbook',
            showNav: true,
          },
          {
            path: '/logbook/details/:id',
            Component: MobilityLogbookDetailsPage,
            name: 'common.mobilityLogbookDetails',
            showNav: true,
          },
          {
            path: '/vehicle-list',
            Component: VehicleListPage,
            name: 'mobility.myVehicles',
            showNav: true,
          },
          {
            path: '/vehicles-checklist',
            Component: VehiclesChecklistPage,
            bgImage: bgChecklist,
            name: 'mobility.vehiclesChecklist',
            showNav: true,
          },
          {
            path: '/vehicles-checklist/:id',
            Component: ChecklistDetailsPage,
            bgImage: bgChecklist,
            name: 'mobility.vehicleChecklistDetails',
            showNav: true,
          },
        ],
      },
      {
        path: '/mobility-planner',
        Component: MobilityPlannerPage,
        bgImg,
        name: 'common.mobilityPlanner',
        showNav: true,
      },
      {
        path: '/reporting-area',
        Component: ReportingAreaMenuPage,
        bgImg,
        name: 'common.reportingArea',
        showNav: true,
        children: [
          {
            path: '/cost-recovery-report',
            Component: CostRecoveryReportPage,
            bgImg,
            name: 'common.costRecoveryReport',
            showNav: true,
          },
          {
            path: '/cost-recovery-report/invoice/:id',
            Component: CreateAgencyInvoicePage,
            bgImg,
            name: 'crumbs.createAgencyInvoice',
            showNav: true,
          },
          {
            path: '/country-reporting',
            Component: CountryReportPage,
            bgImg,
            name: 'crumbs.countryReport',
            showNav: true,
          },
          {
            path: '/dashboard',
            Component: DashboardPage,
            bgImg,
            name: 'crumbs.dashboard',
            showNav: true,
          },
        ],
      },
      {
        path: '/documents',
        Component: ResourcesPage,
        bgImg,
        name: 'common.documents',
        showNav: true,
      },
      {
        path: '/manuals',
        Component: ResourcesPage,
        bgImg,
        name: 'common.manuals',
        showNav: true,
      },
      {
        path: '/trainings',
        Component: ResourcesPage,
        bgImg,
        name: 'common.trainings',
        showNav: true,
      },
    ],
  },
  // UNDSS Travel Request
  {
    path: '/undss-travel-request/profile',
    Component: TravelRequestProfile,
    bgImg,
  },
  {
    path: '/undss-travel-request/trip-list',
    Component: TravelRequestList,
    bgImg,
  },
  {
    path: '/undss-travel-request/trip-edit/:id',
    Component: TravelRequestTripEdit,
    bgImg,
  },
] as RouteElement[];

const buildRoutePaths = (routes: RouteElement[], parentPath = ''): any =>
  routes.map((route) => {
    const path =
      parentPath.replace(/\/$/, '') + route.path.replace(new RegExp(`^${parentPath}`), '');

    return {
      ...route,
      path,
      ...(route.children && { children: buildRoutePaths(route.children, path) }),
    };
  });

const flattenRoutes = (routes: RouteElement[]): Crumb[] =>
  buildRoutePaths(routes)
    .map((route: RouteElement) => [route.children ? flattenRoutes(route.children) : [], route])
    .flat(Infinity)
    .sort((a: Crumb, b: Crumb) => a.path.localeCompare(b.path));

export const routes: Crumb[] = flattenRoutes(routesSchema);
